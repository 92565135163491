.debug-links {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	color: var(--strong);
	background: white;
	margin-top: 2em;

	li {
		padding: 1em;
		margin: 0em;
	}
}

.end-of-list {
	opacity: 0.1;
	padding: 4em;
	text-align: center;
}

.errorFrame {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-wrap: wrap;
	flex-direction: column;
}

.error {
	// border: 1px solid red;
	color: red;
	input {
		border-color: red !important;
	}
}

footer {
	button {
		&:hover {
			opacity: 0.7;
		}
	}
}

.problems-signing-in {
	margin: 0em;
	font-size: 0.75em;
	opacity: 0.5;
	// position: absolute;
	// bottom: 0;
	// left: 0;
	// text-align: center;
	// width: 100%;
	text-align: left;

	&:hover {
		opacity: 0.7;
	}

	button {
		text-align: left;
		color: #fff;
		background: none;
		border: none;
		margin: 0 auto;
		// padding: 1em;
		padding-top: 1em;
	}
}

.fade-enter {
	opacity: 0;
}
.fade-exit {
	opacity: 1;
}
.fade-enter-active {
	opacity: 1;
}
.fade-exit-active {
	opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
	transition: opacity 500ms;
}

footer {
	// margin-top: 20vh;
	div {
		padding: 2em;
		background: linear-gradient(170deg, #0000005e, #00000000);
	}

	p {
		font-size: 0.75em;
		// color: $c-darkblue;
		opacity: 0.5;
		&:last-child {
			margin-bottom: 0em;
		}
	}

	.external-links {
		display: flex;
		justify-content: space-between;
		opacity: 0.8;
		color: #fff;
		margin-bottom: 2em;

		a {
			color: #fff;
		}
	}

	button {
		opacity: 0.8 !important;
		background: none;
		border: none;
		color: #fff;
		padding: 0em;
		font-size: inherit;
	}

	// background-color: var(--dark);
	// background: linear-gradient(180deg, rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0));
}

div.admin + footer {
	display: none;
}
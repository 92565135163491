input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: all 5000s ease-in-out 0s;
}

.multi-input-wrapper {
	display: flex;
	flex-direction: column;

	@include min($medium) {
		flex-direction: row;
		.input-wrapper {
			flex-grow: 1;
			flex-shrink: 1;
			margin-left: 1em;
			&:first-child {
				margin-left: 0;
			}
		}

		&.address .input-wrapper:first-child {
			flex-basis: 20%;
		}
	}
}

.label {
	// opacity: 0.4;
	// text-transform: uppercase;
	// color: #fff;
	font-weight: 600;
	text-align: left;
	// letter-spacing: 0.5px;
	display: block;
	margin: 2em 0em;
	margin-bottom: 0;
}

.required.label,
.required > .label {
	// font-weight: bold;
	// opacity: 0.7;
	&:after {
		content: '*';
		display: inline;
	}
}
label {
	// color: black;
	// text-transform: uppercase;
	// font-size: 0.7em;

	// text-align: left;
	display: block;

	&.label-line {
		input,
		textarea {
			border-top: none;
			border-left: none;
			border-right: none;
		}
	}
}

.checkbox-label,
.radio-label,
.switcher {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 1em;

	input {
		margin: 0 0.5em;
		width: auto;
	}

	.switch {
		margin-right: 1em;
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.4;
	}
}

.radio-label {
	margin-right: 1em;

	input[type='radio'] {
		margin-top: 0em;
	}
}

.switcher {
	cursor: pointer;
}

input,
textarea,
select {
	width: 100%;
	display: block;
	// border: 1px solid rgba(120, 120, 120, 0.2);
	border: 1px solid var(--gray);
	border-radius: 3px;
	box-sizing: border-box;

	padding: 1.1em 0.8em;
	outline: none;
	background: none;
	background-color: rgba(255, 255, 255, 0.95);

	color: black;

	font-size: 0.9em;
	font-family: var(--font-regular);

	&:hover {
		box-shadow: 0 9px 4px -6px rgba(0, 0, 0, 0.07);
	}

	&:focus {
		border-color: var(--strong);
		box-shadow: 0 9px 4px -6px rgba(0, 0, 0, 0.07);
	}

	&:invalid {
		border-color: red;
	}

	&[disabled] {
		opacity: 0.5;
		background-color: rgba(245, 245, 245, 0.95);
	}
}

select {
	appearance: none;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAAXNSR0IArs4c6QAAAJJJREFUGBljZgCCUE9PUW1VVe5rd+58A/HRQaBboJiumiIXSJ4RpPjXt3/7QYrYuJgcV2/f/hpZA7o8E1zy/39tkEaQApgYXDFQDibGCGKgSDAyXgXZBBIH2wxSDBUD2Q7WgE0TSIwBTTFICK4BxEGxCSwLsQ3ZXygaQGpAIfLv57d9IDa2QACJYwCQJmTPIysAAD4OWaVQRa3DAAAAAElFTkSuQmCC);
	background-position: right 1em center;
	background-size: 12px;
	background-repeat: no-repeat;
	background-color: #ffffff;
	transition-duration: 250ms;
	padding-right: 2.5em;
}
input,
textarea,
select,
.ck.ck-reset.ck-editor {
	margin-top: 0.4em;
}

select {
	height: 3.75em;
}

textarea {
	min-height: 15em;
	line-height: 1.3;
}
.ck-editor__editable_inline {
	min-height: calc(7em - 0.4em);
}

label.checkbox {
	& > div {
		padding-top: 0;
		margin: 0;
		// margin-top: 0.4em;
		display: flex;
		align-items: flex-start;

		span {
			margin-top: 0.2em;
			text-align: left;
		}
	}
}

input[type='radio'],
input[type='checkbox'] {
	width: 1.33em;
	height: 1.33em;
	display: inline-block;
	margin: 0 0.5em;
	margin-left: 0;
	margin-top: 0.25em;
	border: 2px solid var(--strong);
	flex-shrink: 0;
}

.radioGroup {
	display: flex;
	flex-wrap: wrap;
	text-align: left;

	.radio {
		flex-shrink: 1;
		flex-basis: 100%;
		padding-right: 1em;

		@include min($small) {
			flex-basis: 50%;
		}

		@include min($medium) {
			flex-basis: 33%;
		}

		@include min($large) {
			flex-basis: 25%;
		}
	}

	&.full-wide {
		.radio {
			flex-basis: 100%;
		}
	}

	.option-sublabel {
		opacity: 0.5;
		display: block;
	}
}

.checkboxesGroup {
	display: flex;
	flex-wrap: wrap;
	text-align: left;

	.checkbox {
		flex-shrink: 1;
		flex-basis: 100%;
		padding-right: 1em;

		@include min($small) {
			flex-basis: 50%;
		}

		@include min($medium) {
			flex-basis: 33%;
		}

		// @include min($large) {
		// 	flex-basis: 25%;
		// }
	}

	&.full-wide {
		.checkbox {
			flex-basis: 100%;
		}
	}

	.option-sublabel {
		opacity: 0.5;
		display: block;
	}
}

/*pic selector*/
.picture-selector {
	border: 1px dashed gray;
	border-radius: 4px;
	width: 300px;
	height: 300px;
	text-align: center;
	margin-bottom: 3em;
	// overflow: hidden;

	&.picture-selected {
		img {
			display: none;
		}
		&:hover {
			img {
				display: inline-block;
			}
		}
	}

	&.isUploading {
		pointer-events: none;
	}
	.error & {
		border-color: red;
	}

	input {
		display: none;
	}

	button {
		margin: 0.5em auto !important;
	}

	.picture-inner {
		width: 100%;
		height: 100%;
		margin-bottom: 1em;

		.picture {
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;

			cursor: pointer;
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				margin-left: -25px;
				margin-top: -25px;
			}
		}
	}

	.actions {
		font-size: 0.9em;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		button {
			padding: 0.4em 1.5em;
		}
	}

	&.contain .picture-inner .picture {
		background-size: contain;
	}
}

fieldset {
	border: none;
	outline: none;
	padding: 1em;
	margin-top: 48px;
	padding-bottom: 48px;
	border-bottom: 1px solid rgba(31, 37, 49, 0.2);

	.required > .label {
		// font-weight: bold;
		// opacity: 0.7;
		&:after {
			content: '*';
			display: inline;
		}
	}

	legend,
	h2 {
		color: #1f2532;
		// font-family: "Sofia Pro";
		font-size: 28px;
		font-weight: 500;
		letter-spacing: -0.2px;
		line-height: 32px;
	}
}

/* --- */
.order-and-signup {
	width: 100%;
	margin-bottom: 10em;
}

.choose-style {
	margin-top: 1em;
	margin-bottom: 1em;
}

.style-option {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	img {
		width: 48px;
		margin-right: 1em;
		background-color: var(--very-light-fade);
	}
}

.input-media {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: flex-start;
	flex: 1 1 auto;
}

.media-manager, .file-manager {
	margin-top: 0.4em;
	// background-color: var(--very-light-fade);
	border-radius: 4px;
	border: 1px solid var(--medium-gray);
	flex: 1 1 auto;
	width: 100%;

	.upload {
		background-color: var(--very-light-fade);
		display: flex;
		flex: 0 1 auto;
		justify-content: flex-start;
		align-items: center;
		padding: 1em;
		border-bottom: 1px solid var(--gray);

		img {
			margin-right: 1em;
		}
		input {
			margin: 0;
		}
	}

	.dragdrop-context {
		.droppable-area {
			display: flex;
			flex-direction: column;
			// justify-content: flex-start;
			// overflow: auto;
			// padding: 6.5em 2em;
			// align-items: center;

			.media-item {
				user-select: none;
				padding: 1em;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				width: 100%;
				
				// max-width: 25%;
				// background-color: white;
				// position: relative;
				// border-radius: 0.5em;
				// margin-right: 1em;
				// box-shadow: 0em 0.5em 0.75em rgba(0, 0, 100, 0.075);
				// overflow: hidden;
				// display: flex;
				// align-items: center;
				transition: max-width 0.33s ease;
				// flex: 0 0 auto;
				// min-height: 8em;
				// border-bottom: 4px solid rgba(0, 0, 0, 0.1);

				& > * {
					padding: 0 0.5em;
					flex: 0 1 auto;
				}

				a:hover {
					text-decoration: underline;
				}

				.delete-media {
					cursor: pointer;
					// position: absolute;
					// top: 0;
					// right: 0;
					// width: 1.1em;
					display: block;					
					// display: none;
					opacity: 0.5;
					margin-left: auto;
				}

				&:hover .delete-media {
					display: block;
					opacity: 1;
				}

				&:first-child {
					// border-bottom: 4px solid var(--accentcolor);
					// max-width: none;
				}
			}
		}
	}
}

.drag-and-drop {
	border-radius: 4px;
	margin-top: 0.5em;

	.add {
		display: flex;
		flex: 0 1 auto;
		justify-content: flex-start;
		align-items: center;
		padding: 1em 0em;
	}

	ul {
		margin-left: -2.8em;
	}

	.drag-and-drop ul {
		margin-left: 0;
	}

	&.fixed ul {
		margin-left: 0em;

		.multi-input-wrapper {
			width: 100%;
		}

		.remove {
			margin: 0em 0em;
			padding: 0.25em 1em;
			margin-top: 1em;
		}
	}

	.droppable-area {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;

		li {
			width: 100%;
			display: flex;
			padding: 1.5em 0em;
			// align-items: flex-end;
			align-items: center;
			justify-content: stretch;
			border-bottom: 1px solid rgba(127, 127, 127, 0.1);

			& > .drag-bar {
				display: flex;
				align-items: flex-end;
				margin: 1em;
			}

			button:not(.ck-button),
			.remove {
				margin: 0.25em 1em;
				padding: 0.25em 1em;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
			}

			.label {
				margin-top: 0em;
			}

			.actions {
				flex: 1 1 auto;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-end;
				align-items: center;
			}

			.multi-input-wrapper {
				flex: 1 1 auto;
			}
		}
	}
}

input[name='price_min_units'],
input[name='price_max_units'] {
	// max-width: 5em;
}

:root {
	// Fonts
	--font-regular: 'Source Sans Pro', sans-serif;

	// Colors
	--theme: #002e85;
	--dark: #14171a;
	--light-blue: #48b8ff;
	--gray: #53565a;
	--medium-gray: #e5e6e6;
	--light-gray: #efefef;
	--very-light-fade: rgba(127, 127, 127, 0.05);
	--strong: #252b70;
	--accentcolor: #009ee2;

	// Misc
	--header-height: #{em(100px)};
	--header-height-small: #{em(60px)};

	// Container
	--default-container: #{rem(865px)};
	--container: #{rem(670px)};
	--container-wide: #{rem(1100px)};
	--container-padding: 1.5rem;
}

// Screen size breakpoints (for min-width queries)
$small: em(480px);
$medium: em(640px);
$large: em(980px);
$xlarge: em(1200px);
$xxlarge: em(1920px);

// Screen size breakpoints (for max-width queries).
$small-max: $medium - em(1px);
$medium-max: $large - em(1px);
$large-max: $xlarge - em(1px);

// Navigation breakpoints
$desktop-nav: $medium;
$mobile-nav-max: $desktop-nav - em(1px);

.c-profile {
	.tools {
		margin-bottom: 2em;

		& > div {
			border-bottom: 1px solid #e5e6ed;
			padding: 1em 1em;

			&:first-child {
				border-top: 1px solid #e5e6ed;
			}
		}

		button,
		label {
			display: block;
			width: 100%;
			border: none;
			background: none;
			text-align: left;
			color: var(--theme);
			padding: 0;
		}

		label {
			display: flex;
			align-items: center;
		}

		button {
			text-decoration: underline;
		}
	}

	.last-login {
		font-style: italic;
		color: #2b2b2b;
		opacity: .6;
		margin-top: 3em;
	}

	.add {
		svg {
			margin-left: 0.6em;
			margin-right: 2.6em;
		}
	}

	.buttons {
		text-align: center;
		margin-top: em(150px);

		@include hide($medium);
	}

	.signout-button {
		background: none;
		border: none;
		padding: 0;
		color: var(--theme);
		font-size: em(12px);
		font-weight: 700;

		.icon {
			content: '';
			width: rem(62px);
			height: rem(62px);
			background: rgba(50, 63, 168, 0.2);
			border-radius: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 0 auto;
			margin-bottom: 1em;
		}
	}
}

// Form

.c-form {
	@include container(rem(570px));

	fieldset {
		border: none;
		padding: 0;
	}

	label {
		// margin-bottom: 2em;
		// display: block;

		span, legend {
			display: block;
			margin-bottom: .5em;
		}

		& > span {
			font-size: em(13px);
			font-weight: 700;
		}

		// Error
		.error-text {
			display: none;
			color: red;
			margin: .5em 0;
			font-size: em(14px);
		}

		&.error {
			input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]), textarea, select {
				border-color: red;
			}

			.error-text {
				display: block;
			}

			.c-checkbox {
				span::before {
					border-color: red;
				}
			}
		}
	}

	// Input
	input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):not([type="file"]), textarea {
		border: none;
		background: #fff;
		display: block;
		width: 100%;
		padding: 1em;
		height: em(50px);
		border: 1px solid var(--theme);
		border-radius: 4px;

		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: var(--dark);
			opacity: 1;
		}

		&::-moz-placeholder { /* Firefox 19+ */
			color: var(--dark);
			opacity: 1;
		}

		&:-ms-input-placeholder { /* IE 10+ */
			color: var(--dark);
			opacity: 1;
		}

		&:-moz-placeholder { /* Firefox 18- */
			color: var(--dark);
			opacity: 1;
		}
	}

	// Textarea
	textarea {
		height: em(180px);
	}

	// Select
	.select {
		position: relative;
		height: em(50px);

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 1em;
			top: 50%;
			width: 0;
			height: 0;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
 			border-top: 6px solid var(--dark);
 			margin-top: -3px;
		}

		select {
			width: 100%;
			height: em(50px);
			background: none;
			border: 1px solid #ccc;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border-radius: 0;
			padding: 0 .7em;
			margin: 0;
		}
	}

	.buttons {
		text-align: center;
		margin: 2em 0;
	}

	&.narrow {
		max-width: rem(270px);
	}
}

.admin-link {
	// background-color: salmon;

	&:hover {
		text-decoration: underline !important;
	}

	.c-messages & {
		top: -2.5em;
		position: relative;
		padding: 0.2em 0.8em;
	}
}

.c-messages {
	@include container(var(--container));
	width: 100%;

	&.m-single {
		.item {
			opacity: 1 !important;
			transform: none !important;
			box-shadow: none !important;
			// padding-left: 1em;
			// padding-right: 1em;
			padding-top: 0.5em;
			padding-bottom: 2em;

			header {
				margin-bottom: 1em;
				margin-left: 0em;
				margin-top: 0em;
				text-align: left;

				.item-icons {
					position: relative;
					top:0;
					left:0;
				}

				.timestamp {
					display: block;
				}
			}
			.footer {
				.timestamp {
					display: none;
				}
			}
		}

		.item.basic .feed-message {
			max-height: none !important;
			padding: 0;

			&:after {
				display: none;
			}
		}
	}

	& > a {
		display: block;
		position: relative;
	}

	&:not(.m-single) {
		.item {
			&.not_published {
				opacity: 0.66;
			}
			// &.not_sent {
			// 	opacity: 0.66;
			// }

			header {

				.timestamp {
					// display: none;
					margin-top: 1em;
				}
			}
		}
		&.basic {
			margin-bottom: 0em;
			margin: 1.5rem;
		}
	}

	.item {
		border-radius: 8px;
		background: #fff;
		// padding: 2em;
		// overflow: hidden;
		color: var(--theme);
		padding-bottom:1px; /* prevent collapsing margins */
		// padding-top: 1px;
		margin-bottom: 1.5em;

		h4 {
			font-size: 1.2rem;
		}
		@media (max-width: $medium-max) {
			h4 {
				font-size: 1.1rem;
			}
		}

		ul, ol {
			line-height: 1.6;

			@include block-margin-small();

			li {
				margin-left: 2rem;
				padding-left: .5rem;

				&::marker {
					color: var(--theme);
				}
			}
		}

		blockquote {
			font-weight: bold;
  			font-style: italic;
		}

		header {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			// margin-left: -1.5em;
			// margin-top: -1.5em;
			
			
			// margin-left: -3.2em;
  			// margin-top: -2.8em;

			.item-icons {				
				position: absolute;
				top: -0.8em;
				left: -1.1em;
				margin-right: 0.5em;
			 	display: flex;
			  	align-items: flex-start;
				justify-content: center;
				flex-direction: row;

				span.icon {
					background:#fff;
					border-radius: 50%;					
					width: 1.8em;
					height: 1.8em;
					display: flex;
					align-content: center;
					justify-content: center;
					align-items: center;
					margin: 0.2em 0em;
				}
			}



			.timestamp {
				display: none;
			}
		}
		.footer {
			display: block;
			padding: 0.8em;
			text-align: center;

			// border-top: 1px solid #f8f8ff;
			// background: ghostwhite;
			margin-top: 1em;
			border-radius: 8px;

			.timestamp {
				display: block;
				opacity: 0.5;
			}
		}

		.c-read-status {
			display: block;
			position: absolute;
			// margin-right: -1.5em;
			// margin-bottom: -1.5em;
			// margin-left: auto;
			right: 0.5em;
  			bottom: 0.5em;
			  width:1.6em;
		}

		time {
			font-size: em(14px);
			font-weight: 700;
			opacity: 0.7;
		}

		img:not(.icon) {
			margin-bottom: 1em;
			@media (min-width: $medium-max) {
				margin-bottom: 2em;
			}
		}

		.button {
			text-align: center;
			margin-top: 2em;
			// position: sticky;
			position: relative;
			bottom: 1.5em;

			.c-button {
				padding-left: 2em;
				padding-right: 2em;
				background: rgba(255, 255, 255, 0.9);
			}
		}


		.thumb {
			display: block;
			margin-left:auto;
			margin-right: auto;				
			border-radius:6px 6px 0px 0px;
		}

		&.basic {
			transition: all 0.33s cubic-bezier(0.23, 1, 0.32, 1);
			&:hover {
				// transform: translateX(0.15em) translateY(-0.15em);
				transform: scale(1.02);
				box-shadow: -0.66em 0.66em 1em rgba(0, 0, 0, 0.125);
			}

			.thumb + .feed-message {
				margin-top: 0em;
				padding-top: 0em;

				@media (min-width: $medium-max) {
					max-height: 7.5em;
				}
			}
			.feed-message {
				max-height: 7.5em;
				
				@media (min-width: $medium-max) {
				 	max-height: 9em;
				}

				overflow: hidden;
				position: relative;
				line-height: 1.4;
				padding:0em 2em;
				padding-top:2em;
				// margin-bottom:2em;

				/* gradient spacing fix */
				// padding-bottom: 1.5rem;
				// margin:0em;

				&:after {
					content: ' ';
					position: absolute;
					bottom: 0;
					height: 1.5em;
					width: 100%;
					background: linear-gradient(0deg, #ffffffff, #ffffff00);
					left: 0;
					// opacity: 0;
				}

				p:first-of-type {
					margin-top: 0;
				}
	
			}
			.file-list {
				display: flex;
				flex-direction: column;
  				align-content: flex-start;
				padding-left: 2em;

				a {
					font-weight:bold;
					padding: 0.4em;
					padding-left: 0;
					color: #0d76b9;
				}

				a:before {
					content: "📎";
					margin-left: -2em;
  					text-decoration: none;
  					position: absolute;
					
				}

				a:link, a:visited, a:hover, a:active {
					text-decoration: underline;
				}
			}
		}
		// Quiz
		&.quiz {
			background: var(--light-blue)
				linear-gradient(352deg, #000e7b24, transparent 60%);
			text-align: center;
			color: #fff;			

			h2 {
				font-size: 1.2em;
				font-weight: 700;
				margin-bottom: 0;

				& + p {
					margin-top: 0;
				}
			}

			.buttons {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.c-button {
					text-transform: none;
					position: relative;
					overflow: hidden;
					z-index: 1;
					text-shadow: 1px 1px rgba(0, 0, 0, 0.1);

					.bar {
						transition: width 0.33s ease;
						display: none;
						content: ' ';
						width: 0%;
						opacity: 0;
						position: absolute;
						background: rgba(250, 250, 250, 0.33);
						background: linear-gradient(
							45deg,
							rgba(250, 250, 250, 0),
							rgba(250, 250, 250, 0.33)
						);
						top: 0;
						left: 0;
						height: 100%;
						z-index: -1;
					}

					span {
						position: absolute;
						margin-left: 0.25em;
						opacity: 0.5;
					}
				}

				&.m-answered {
					.c-button {
						cursor: default;

						&:hover {
							opacity: 1;
						}

						.bar {
							display: block;
							opacity: 1;
							background: linear-gradient(
								45deg,
								rgba(250, 250, 250, 0),
								rgba(250, 250, 250, 0.4)
							);
						}
					}
				}
			}

			button {
				background: none;
				border: 2px solid #fff;
				border-radius: 20px;
				padding: 0.5em 3em;
				color: #fff;
				margin-bottom: 1em;
				min-width: rem(220px);
				font-size: em(16px);
				font-weight: 600;

				&.m-active {
					background: #fff;
					color: var(--theme);
					box-shadow: -0em 0.4em 1em rgba(0, 0, 0, 0.1);
				}
			}
		}

		// Form
		&.form {
			background: var(--light-blue);
			text-align: center;
			color: #fff;

			form {
				@include container(em(330px));
			}
		}

		.padded-content {
			padding: 2em;
			padding-top:3em;
		}
		.thumb + .padded-content {
			padding-top:0em;
		}
	}

	a {
		&:hover {
			text-decoration: none;
		}
	}

	.show-all {
		position: relative;
		text-align: center;
		display: inline-block;
		margin-bottom: 1em;
		margin-top:-2em;
		// padding: 0.3em 0;

		select {
			font-size: em(12px);
			font-weight: 700;		
			appearance: none;
			border: none;
			color: rgba(255, 2555, 255, 0.5);
			background: transparent;
			padding: 1em 2em;

			option {
				font-size: 1rem;
			}
		}

		&::after {
			content: '';
			position: absolute;
			// right: -1em;
			right: 0em;
			top: 50%;
			width: 0;
			height: 0;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 6px solid rgba(255, 2555, 255, 0.5);
			margin-top: -2px;
		}
	}
}

// Abstracts
@import 'abstracts/functions';
@import 'abstracts/variables';
@import 'abstracts/mixins';

// Base
@import 'base/base';
@import 'base/grid';
@import 'base/helpers';
@import 'base/typography';
@import 'base/print';

@import 'mixins';

// Layout
@import 'layout/article';
@import 'layout/form';
@import 'layout/header';
@import 'layout/navigation';
@import 'layout/page';
@import 'layout/search';

// Components
@import 'components/article_nav';
@import 'components/button';
@import 'components/checkbox';
@import 'components/contacts';
@import 'components/contacts_item';
@import 'components/manual';
@import 'components/messages';
@import 'components/onboarding';
@import 'components/page_header';
@import 'components/person';
@import 'components/profile';
@import 'components/splash';
@import 'components/switch';

// Pages

// Themes

// Vendor
@import 'reactcarousel';
@import 'reacttable';
@import 'toastify';

// @import 'base';

@import 'pages';
// @import 'form';
@import 'visuals';
// @import 'admin';
@import 'testing';

// the :export directive is the magic sauce for webpack
:export {
	accentcolor: var(--accentcolor);
	verylightfade: var(--very-light-fade);
}

.c-contacts-item {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	background: #fff;
	color: var(--theme);
	padding: 0.5em;
	border-radius: 8px;
	line-height: 1.3;
	position: relative;

	&:hover {
		text-decoration: none;
	}

	// &::after {
	// 	content: '';
	// 	position: absolute;
	// 	right: 0.5em;
	// 	top: 0.5em;
	// 	width: rem(28px);
	// 	height: rem(28px);
	// 	background: no-repeat center center;
	// 	box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
	// 	border-radius: 50%;
	// }

	// &.user-group-1 {
	// 	&::after {
	// 		background-image: url('/images/contacts/toimisto.svg');
	// 	}
	// }

	// &.user-group-2 {
	// 	&::after {
	// 		background-image: url('/images/contacts/lampo.svg');
	// 	}
	// }

	// &.user-group-3 {
	// 	&::after {
	// 		background-image: url('/images/contacts/ilma.svg');
	// 	}
	// }

	// &.user-group-4 {
	// 	&::after {
	// 		background-image: url('/images/contacts/sahko.svg');
	// 	}
	// }

	// &.user-group-5 {
	// 	&::after {
	// 		background-image: url('/images/contacts/muut.svg');
	// 	}
	// }

	.groups {
		// &::after {
		// 	content: '';
		// position: absolute;
		right: 0.5em;
		top: 0.5em;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin-right: 0;
		flex-grow: 0;
		align-self: flex-start;

		.group {
			width: rem(28px);
			height: rem(28px);
			background: #fff no-repeat center center;
			box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
			border-radius: 50%;
			&:not(:first-child) {
				margin-left: -0.66em;
			}
		}

		.user-group-1 {
			background-image: url('/images/contacts/toimisto.svg');
		}

		.user-group-2 {
			background-image: url('/images/contacts/lampo.svg');
		}

		.user-group-3 {
			background-image: url('/images/contacts/ilma.svg');
		}

		.user-group-4 {
			background-image: url('/images/contacts/sahko.svg');
		}

		.user-group-5 {
			background-image: url('/images/contacts/muut.svg');
		}

		// .user-group-6,
		.user-group-11 {
			background-image: url('/images/contacts/car.svg');
		}
	}

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-right: 1.5em;
		flex-grow: 1;
	}

	.last {
		text-transform: uppercase;
		font-weight: 700;
	}

	img,
	svg {
		width: rem(66px);
		height: rem(66px);
		border-radius: 50%;
		margin-right: 1em;
		flex-shrink: 0;
	}

	svg path {
		fill: var(--light-gray);
	}

	p {
		span {
			display: block;
		}
	}
}

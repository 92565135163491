.c-splash {
	/* TESTING */
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #011093 url("/images/body-bg-center.png") center center no-repeat;
	background-size: 100% auto;

	@media (min-width: $medium) {
		background-image: url("/images/body-bg-center-large.png");
		background-size: em(1920px) auto;
		background-position: center center;
	}
}

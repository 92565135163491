// Body

html {
	height: 100%;
}

body {
	background: #011093 url('/images/body-bg-top.png') no-repeat top center;
	background-size: 100% auto;
	height: 100%;

	@media (min-width: $medium) {
		background-image: url('/images/body-bg-top-large.png');
		background-size: em(1920px) auto;
	}

	& > div {
		height: 100%;
	}
}

// Container

.container {
	position: relative;

	@include container(var(--default-container), true);

	padding-bottom: 1px;

	.container {
		padding-left: 0;
		padding-right: 0;
	}
}

.app-container {
	margin: 0;
	max-width: none;
	background: linear-gradient(343deg, #0000005e, #00000000);
	min-height: 100%;

	@media (min-width: $large) {
		padding: 0 em(190px);
	}
}

.login-container {
	min-height: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.loginForm {
		padding-bottom: 3em;
	}
}

// Breakpoints

meta.breakpoints-small-max {
	@include variable($small-max);
}

meta.breakpoints-medium {
	@include variable($medium);
}

meta.breakpoints-medium-max {
	@include variable($medium-max);
}

meta.breakpoints-large {
	@include variable($large);
}

meta.breakpoints-large-max {
	@include variable($large-max);
}

meta.breakpoints-xlarge {
	@include variable($xlarge);
}

// Focus

html.focus-visible {
	:focus:not(.focus-visible):not(input):not(textarea):not(select) {
		outline: 0;
	}
}

// Screen reader only

.sr-only {
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
	height: 1px;
	width: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
}

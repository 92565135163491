.manual {
	section {
		max-width: var(--container);
	}

	.wp-page {
		padding: 2em;
		background: white;
		// color: $c-strong;
	}
}

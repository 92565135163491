// Checkbox

.c-checkbox {
	$size: 1.7em;
	$padding: .7em;

	position: relative;
	margin-right: .5em;
	display: inline-block;
	margin: 1em 0;

	& > span {
		display: inline-block;
		vertical-align: middle;
		user-select: none;
		padding-left: $size + $padding;
		font-weight: 400 !important;
		font-size: 1rem !important;

		& > span {
			content: "";
			display: inline-block;
			width: $size;
			height: $size;
			line-height: $size;
			border: 3px solid var(--light-blue);
			margin-right: $padding;
			border-radius: 50%;
			vertical-align: middle;
			position: absolute;
			left: 0;
			top: em(-5px);
		}
	}

	input:checked + span > span::before {
		content: "";
		text-align: center;
		display: block;
		transform: rotate(45deg) scale(.6) translateY(-3px) translateX(-1px);
		width: 14px;
		height: 24px;
		border-bottom: 7px solid var(--light-blue);
		border-right: 7px solid var(--light-blue);
		margin: 0 auto;
	}

	input:focus-visible + span > span {
		outline: 2px solid #85b3fa;
		outline: 2px solid -webkit-focus-ring-color;
	}

	input {
		opacity: 0;
		position: absolute;
		width: 1em;
		height: 1em;

		&[disabled] {
			& + span > span {
				opacity: .5;
			}
		}
	}
}

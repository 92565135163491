.c-search {
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: none;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 1.5em 1.5em;
	overflow: auto;
	background: #011093 url('/images/body-bg-top.png') top center no-repeat;
	background-size: 100% auto;

	@media (min-width: $medium) {
		background-image: url('/images/body-bg-top-large.png');
		background-size: em(1920px) auto;
		background-position: top center;
	}

	h1 {
		font-size: 1em;
		font-weight: 700;
		margin-bottom: em(125px);
	}

	h3 {
		font-size: em(15px);
		font-weight: 700;
		margin-top: 2em;
		margin-bottom: 0.7em;
	}

	input[type='text'] {
		background: rgba(216, 216, 216, 0.3);
		padding: 1em 3em;
		border: none;
		border-radius: 4px;
		text-align: center;
		color: #fff;
		font-weight: 700;

		&::placeholder {
			opacity: 1;
			color: #ddd;
			font-weight: normal;
		}
	}

	.close {
		position: absolute;
		right: 1.2em;
		top: 0.7em;
		background: none;
		border: none;
		width: em(44px);
		height: em(44px);
		transform: rotate(45deg);

		&::before,
		&::after {
			content: '';
			width: em(24px);
			height: em(6px);
			background: #fff;
			border-radius: 1px;
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: em(-12px);
			margin-top: em(-3px);
		}

		&::after {
			transform: rotate(90deg);
		}
	}

	.contacts,
	.c-messages {
		width: 100%;

		@include container(var(--container));
	}

	.c-manual {
		li {
			margin-bottom: 0.5em;
		}

		a {
			display: block;
			color: var(--theme);
			font-size: em(20px);
			padding: 1.2em 0;
			padding-left: 1em;

			background: #fff;
			border-radius: 8px;
			padding: 1em;
		}
	}

	&.m-open {
		display: flex;
	}

	&.m-closed {
		display: none;
	}

	.c-contacts-item {
		margin-bottom: 0.5em;
	}
}

// Main nav

.c-navigation {
	margin-top: 2em;
	margin-bottom: 3em;

	&:not(.mobile) {
		@include show($large);
	}

	@media (min-width: $large) {
		position: absolute;
		left: em(-180px);
		top: em(120px);
	}

	ul {
		@media (max-width: $medium-max) {
			display: flex;
			justify-content: center;
		}
	}

	li {
		position: relative;
		margin: 0 0.5em;

		&.desktop {
			@include show($medium);
		}

		@media (min-width: $large) {
			margin-bottom: 2em;
		}

		a,
		button {
			background-color: transparent;
			border: none;
			transition: opacity 0.2s linear;
			color: #fff;
			font-size: em(12px);
			font-weight: 700;
			display: block;
			width: rem(72px);
			text-align: center;
			line-height: 1.3;

			@media (min-width: $large) {
				font-size: em(15px);
				width: auto;
			}

			&:hover {
				text-decoration: none;
				opacity: 0.7;
			}

			.icon {
				content: '';
				display: block;
				width: rem(52px);
				height: rem(52px);
				border-radius: 50%;
				background: rgba(255, 255, 255, 0.2);
				margin: 0 auto;
				margin-bottom: 1em;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			&.active {
				.icon {
					background: #fff;
				}

				svg {
					path {
						fill: #050f93;
					}
				}
			}
		}
	}
}

.c-onboarding {
	text-align: center;

	.icon {
		width: em(246px);
		height: em(246px);
		background: #2463c9;
		border-radius: 50%;
		margin: 0 auto;
		margin-bottom: 2em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		svg {
			path {
				fill: var(--light-blue);
			}
		}

		&.desktop {
			background: url('/images/desktop-icons.jpg') no-repeat;
			background-size: 100% auto;
		}
	}

	p {
		font-size: em(20px);

		&.small {
			font-size: em(18px);
		}
	}

	a {
		color: var(--light-blue);
	}

	img {
		width: rem(100px);
	}

	.skip {
		background: none;
		border: none;
		padding: 0;
		color: var(--light-blue);
		font-size: em(18px);
		display: inline-block;
		margin-bottom: 1.5em;
	}

	.c-switch {
		& > span {
			margin-right: 1em;
		}

		input {
			& + span {
				background: rgba(255, 255, 255, 0.24);

				&::before {
					background: #fff;
				}
			}

			&:checked {
				& + span {
					background: #fff;

					&::before {
						background: var(--light-blue);
					}
				}
			}
		}
	}

	.slide {
		cursor: grab;
		.slide-content {
			@include container(rem(320px));
		}
	}
	.BrainhubCarouselItem {
		cursor: grab;
		& > div {
			@include container(rem(320px));
		}
	}

	.BrainhubCarousel__dots {
		margin-top: 2rem;
		margin-bottom: rem(30px);

		li {
			margin: 0 0.35rem;
		}

		.BrainhubCarousel__thumbnail {
			background-color: #fff;
			border-radius: 50%;
			width: 8px;
			height: 8px;
			display: block;
			padding: 0;
		}
	}

	.tns-nav {
		margin-top: 2rem;
		margin-bottom: rem(30px);
		display: flex;
		flex-direction: row;
		justify-content: center;

		button {
			margin: 0 0.35rem;
			background-color: #fff;
			border-radius: 50%;
			border: none;
			width: 8px;
			height: 8px;
			display: block;
			padding: 0;
			opacity: 0.5;

			&.tns-nav-active {
				opacity: 1;
			}
		}
	}

	.tns-controls {
		button {
			transition: opacity 0.33s ease-in-out;
			opacity: 0.5;
			font-size: 3em;
			font-weight: bold;
			background: none;
			border: none;
			color: #fff;
			display: none;

			&[disabled] {
				opacity: 0.1;
			}

			&[data-controls='next'] {
				position: absolute;
				right: 0;
				top: 50%;
			}
			&[data-controls='prev'] {
				position: absolute;
				left: 0;
				top: 50%;
			}

			@include min($medium) {
				display: block;
			}
		}
	}

	.BrainhubCarousel__customArrows {
		button {
			opacity: 0.25;
			font-size: 3em;
			font-weight: bold;
			background: none;
			border: none;
			color: #fff;
			display: none;

			@include min($medium) {
				display: block;
			}
		}

		&.BrainhubCarousel__arrow--disable {
			visibility: hidden;
		}
	}
}

// Typography

body {
	font-family: var(--font-regular);
	color: #fff;
	-webkit-font-smoothing: antialiased;
}

h1 {
	font-weight: 400;
	font-size: rem(58px);
	line-height: 1.2;
	margin: 0 0 0.5em 0;
}

h2 {
	font-weight: 400;
	font-size: rem(32px);
	line-height: 1;
	margin: 0 0 0.5em 0;
}

h3 {
	font-weight: 400;
	font-size: rem(24px);
	line-height: 1;
	margin: 0 0 0.5em 0;
}

h4 {
	font-weight: 400;
	font-size: rem(20px);
	line-height: 1;
	margin: 0 0 0.5em 0;
}

p {
	font-size: rem(16px);
	line-height: 1.6;
	margin: 1.5rem 0;

	@media (min-width: $medium) {
		font-size: rem(18px);
	}
}

a {
	color: var(--theme);

	&:link {
		text-decoration: none;
	}

	&:visited {
		text-decoration: none;
	}

	&:hover {
		text-decoration: underline;
	}

	&:active {
		text-decoration: none;
	}
}

.c-page-header {
	@include container(rem(510px));

	text-align: center;
	margin-top: 2em;
	margin-bottom: 2em;

	h1 {
		text-transform: uppercase;
		font-size: em(31px);
		font-weight: 700;
		margin-bottom: 1em;

		@media (min-width: $medium) {
			font-size: em(38px);
		}


		a,span:not(.title) {
			font-size: 0.5em;
		}
		.title {
			display: block;
		}
	}

	p {
		font-size: em(20px);
	}

	a {
		color:var(--light-gray)
	}
}
// Header

.c-header {
	height: var(--header-height-small);
	transition: height .2s linear, transform .2s ease-out, background .2s ease-out, box-shadow .2s ease-out;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: .5em;
	padding-top: .5em;

	@media (min-width: $desktop-nav) {
		padding-left: 3em;
		padding-right: 3em;
		height: var(--header-height);
	}

	.back {
		background: none;
		border: none;

		@media (min-width: $medium) {
			visibility: hidden;
		}
	}

	.search {
		background: none;
		border: none;
		color: #fff;
	}

	// Logo
	.logo {
		// transition: top .2s ease-out;
		color: #fff;

		svg {
			width: em(98px);
			// transition: width .2s ease-out, height .2s ease-out;
			// width: em(80px);
			// height: em(23px);

			// @media (min-width: $desktop-nav) {
			// 	width: em(120px);
			// 	height: em(34px);
			// }
		}
	}

	// Logged out
	&.logged-out {
		justify-content: center;

		.back {
			display: none;
		}

		.search {
			display: none;
		}
	}

	// Skip link
	.skip-link {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		text-align: center;

		a {
			position: absolute;
			width: 1px;
			height: 1px;
			margin: -1px;
			overflow: hidden;
			clip: rect(0,0,0,0);
			border: none;
			background: #fff;
			padding: 1em 3em;
			display: inline-block;

			&:focus {
				position: static;
				width: auto;
				height: auto;
				margin: 0;
				overflow: visible;
				clip: auto;
			}
		}
	}
}

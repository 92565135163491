.c-person {
	text-align: center;

	@media (min-width: $medium) {
		text-align: left;
	}

	.row {
		@media (min-width: $medium) {
			display: flex;
		}

		& > div {
			@media (min-width: $medium) {
				@include column(1/2);
			}
		}
	}

	.image {
		@media (min-width: $medium) {
			@include push(1/2);
			padding-top: 4em;
		}
	}

	.text {
		@media (min-width: $medium) {
			padding-top: 4em;

			@include pull(1/2);
		}
	}

	.last {
		text-transform: uppercase;
		font-weight: 700;
	}

	.groups {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;		

		@media (max-width: $small-max) {
			align-content: center;
		}
	}
	.group {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;

		@media (max-width: $medium-max) {
		}

		&::after {
			content: "";
			width: rem(28px);
			height: rem(28px);
			background: no-repeat center center;
			background-size: contain;
			display: inline-block;
			vertical-align: middle;
			
			margin-left: .5em;
			@media (max-width: $small-max) {
				margin-left: 2em;
			}
		}

		&.user-group-1 {
			&::after {
				background-image: url("/images/contacts/toimisto.svg");
			}
		}

		&.user-group-2 {
			&::after {
				background-image: url("/images/contacts/lampo.svg");
			}
		}

		&.user-group-3 {
			&::after {
				background-image: url("/images/contacts/ilma.svg");
			}
		}

		&.user-group-4 {
			&::after {
				background-image: url("/images/contacts/sahko.svg");
			}
		}

		&.user-group-5 {
			&::after {
				background-image: url("/images/contacts/muut.svg");
			}
		}

		// &.user-group-6, 
		&.user-group-11 {
			&::after {
				background-image: url("/images/contacts/car.svg");
			}
		}
	}

	h1 {
		font-size: em(31px);

		span {
			text-transform: uppercase;
			font-weight: 700;
		}
	}

	p {
		@media (min-width: $medium) {
			font-size: em(20px);
		}

		&.italic {
			font-style: italic;
		}
	}

	.image {
		svg {
			height: auto;

			path {
				fill: var(--light-gray);
			}
		}

		svg,
		img {
			border-radius: 50%;
			display: block;
			margin: auto;
			margin-bottom: 2em;
			width: em(232px);
			max-width: 100%;

			@media (min-width: $medium) {
				width: em(322px);
				margin-right: 0;
			}
		}
	}

	.buttons {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		margin-top: 3em;

		@media (min-width: $medium) {
			justify-content: flex-start;
			flex-direction: column;
		}

		button,
		a {
			background: none;
			border: none;
			padding: 0;
			font-size: em(12px);
			font-weight: 700;
			color: var(--theme);
			margin: 0 0.5em;
			margin-bottom: 1em;
			line-height: 1.3;
			max-width: rem(85px);

			@media (min-width: $medium) {
				max-width: none;
				font-size: inherit;
			}

			.icon {
				content: '';
				display: block;
				width: rem(62px);
				height: rem(62px);
				margin: 0 auto;
				margin-bottom: 1em;
				border-radius: 50%;
				vertical-align: middle;
				background: rgba(50, 63, 168, 0.2) no-repeat center center;

				@media (min-width: $medium) {
					display: inline-block;
					margin-right: 1.2em;
					margin-bottom: 0em;
				}
			}

			&.email {
				.icon {
					background-image: url("/images/email.svg");
				}
			}

			&.phone {
				.icon {
					background-image: url("/images/phone.svg");
				}
			}
		}
	}

	// Profile
	&.profile {
		h1 {
			font-size: em(16px);

			@media (min-width: $medium) {
				font-size: em(20px);
			}
		}

		@media (max-width: $small-max) {
			text-align: left;

			.row {
				align-items: center;
				display: flex;
			}

			.text {
				padding-top: 0;
			}

			img,
			svg {
				width: rem(66px);
				margin-right: 1em;
			}
		}
	}
}

.c-manual {
	margin: 0;
	padding: 0;
	margin-bottom: 3em;
	list-style: none;

	li {
		margin-bottom: 0.5em;

		

		&:last-child {
			border-bottom: 0px;
		}

	}


	&:not(.sub) {
		& > li {
			// @media (min-width: $medium) {
			// 	border-top: 1px solid var(--theme);
			// }

			// &:first-child {
			// 	border-top: 0px;
			// }

			border-bottom: 1px solid var(--light-gray);
		}
	}

	&.sub {
		// display: none;
		font-size: 0.9em;
		margin: 0em 1em;
		margin-top: -0.25em;
		margin-bottom: 0.25em;
		// margin-left: 4em;
  		// margin-top: 0.6em;
		// margin-bottom: 3em;

		li {
			// border-top: 1px solid var(--theme);
			// &:first-child {
			// 	border-top: 0px;
			// }
			margin:0;
			border-bottom: 1px solid #e5e6ed;

			&:last-child {
				border-bottom: 0px;
			}

			a {
				padding:0.9em;
				font-weight: normal;
			}
		}

		// li:last-child {
		// 	border-bottom: 0px;
		// }
	}


	a {
		display: block;
		color: var(--theme);
		font-size: em(20px);
		padding: 1em 0;
		padding-left: 1em;
		text-transform: uppercase;
		font-weight: bold;
		transition: all 0.3 ease;
		background-color: transparent;

		&::before {
			content: "» ";
		}

		&:hover {
			background-color: var(--light-gray);
		}


		@media (max-width: $small-max) {
			background: #fff;
			border-radius: 8px;
			padding: 1em;
		}
	}
}
